// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useTranslation 
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ExternalStorageCreateForm 
} from "../../components/externalStorage/ExternalStorageCreateForm"

import { 
    useActiveOrganisationId 
} from "../../hooks/useActiveOrganisationId"

import { 
    useIsOnAdminPage 
} from "../../hooks/useIsOnAdminPage"

/**
 * The CreateExternalStorageRequestsPage component renders the CreateExternalStorageRequests page of the application.
 */
export const CreateExternalStoragePage = () => {
    const { t } = useTranslation()

    const activeOrganisationId = useActiveOrganisationId() 
    const isOnAdminPage = useIsOnAdminPage() 
     

    const externalStorageCreateForm = () => {
        if (isOnAdminPage) {
            return (
                <ExternalStorageCreateForm 
                    organisationId = {0}
                />
            )
        } else if (activeOrganisationId !== 0) {
            return (
                <ExternalStorageCreateForm 
                    organisationId = {activeOrganisationId}
                />
            )
        }
    }    


    return(
        <Card> 
            <h1>{t("ExternalStorages.createText")}</h1> 
            {externalStorageCreateForm()}
        </Card>      
    )
}