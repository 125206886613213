// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff

import { 
    useTranslation
} from "react-i18next"

import {
    Card,
} from "@mantine/core"


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    ExternalStorageUpdateForm 
} from "../../components/externalStorage/ExternalStorageUpdateForm"

import { 
    selectExternalStoragesById
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    selectExternalStorageSingleId, 
} from "../../features/externalStorage/externalStorageSingleIdSlice"

import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ErrorNavigator
} from "../../components/general/ErrorNaviagtor"

import { 
    ViewUpdateDeletePagesBar
} from "../../layouts/menuBars/general/ViewUpdateDeletePagesBar"

import { 
    GoToTableButton 
} from '../../components/buttons/goTo/GoToTableButton'

import { 
    GoFromToViewPageButton
} from "../../components/buttons/goFromTo/GoFromToViewPageButton"

import { 
    GoFromToDeletePageButton 
} from "../../components/buttons/goFromTo/GoFromToDeletePageButton"

import { 
    LastEntryItemButton 
} from "../../components/buttons/lastEntry/LastEntryItemButton"

import { 
    NextEntryItemButton 
} from "../../components/buttons/nextEntry/ForwardBackItemBar"


/**
 * The UpdateExternalStorageRequestsPage component renders the UpdateExternalStorageRequests page of the application.
 */
export const UpdateExternalStoragePage = () => {
    const { t } = useTranslation()   
   
    //Selectors
    const externalStorages = useAppSelector(selectExternalStoragesById)
    const editExternalStorageId = useAppSelector(selectExternalStorageSingleId).externalStorageId


    //Navigate to the table if editPlaceId is not edfined (= 0)
    if (editExternalStorageId === 0) {
        return (            
            <ErrorNavigator/>
        )
    }


    
    return(
        <Card>  
            <h1>{t("ExternalStorages.updateText")}</h1> 
            <ViewUpdateDeletePagesBar
                lastEntryButton = {<LastEntryItemButton/>}
                navigationButton1 = {<GoFromToViewPageButton/>}
                navigationButton2 = {<GoFromToDeletePageButton/>}
                navigationTableButton = {<GoToTableButton/>}
                nextEntryButton = {<NextEntryItemButton/>}
            /> 
            <ExternalStorageUpdateForm 
                externalStorageId = {editExternalStorageId}
                initialExternalStorageInfo = {externalStorages[editExternalStorageId].externalStorageInfo!}
                updateUser = { externalStorages[editExternalStorageId].updateUser}
                updateTime = {externalStorages[editExternalStorageId].updateTime!}
            />
        </Card>            
    )
}