// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    createSelector, 
    createSlice, 
    PayloadAction 
} from "@reduxjs/toolkit"

import 
    jwt_decode 
from "jwt-decode"

import { 
    clientId 
} from "../../app/Consts"




// Imports from this projects
// Pages, Components, Features, Hooks


export interface AuthState {
    isAuthenticated: boolean
    tokens?: any
    userInfo?: {
        azp: string
        exp: number
        prefered_username: string
        family_name: string
        given_name: string
        name: string
        resource_access: {
            [id: string]: {
                roles: string[]
            }
        }
    }
}


const initialState: AuthState = {
    isAuthenticated: false,
}

const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthState(state, { payload }: PayloadAction<AuthState>) {
            return payload
        },
    },
})

export const { 
    setAuthState
} = auth.actions

export default auth.reducer

// selectors
type AuthSliceRoot = {
    auth: ReturnType<typeof auth.reducer>
}

export const selectTokens = (state: AuthSliceRoot) => state.auth.tokens
export const selectUserInfo = (state: AuthSliceRoot) => state.auth.userInfo
export const selectIsAuthenticated = (state: AuthSliceRoot) => state.auth.isAuthenticated

export const selectAccessToken = createSelector([selectTokens], (tokens) => {
    if (tokens) {
        return tokens.token
    }
    return undefined
})


export const selectUserRoles = createSelector([selectUserInfo], (userInfo) => {
    if (
        userInfo &&
        userInfo.resource_access &&
        userInfo.resource_access[clientId]
    ) {
        return userInfo.resource_access[clientId].roles
    }
    return [] as string[]
})

// Workaround to get these fields until supported by auth library
export const selectSubAndEmail = createSelector([selectTokens], (tokens) => {
    if (tokens === undefined) 
        return { sub: "", email: "" }
    const decodedJwt = jwt_decode(tokens.idToken)
    const subAndEmailInfo = Object.assign({ sub: "", email: "" }, decodedJwt)
    return subAndEmailInfo
})

// Workaround to get these fields until supported by auth library
export const selectSub = createSelector([selectTokens], (tokens) => {
    if (tokens === undefined) 
        return { sub: ""}
    const decodedJwt = jwt_decode(tokens.idToken)
    const subInfo = Object.assign({ sub: ""}, decodedJwt)
    return subInfo
})