// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect 
} from 'react'

import { 
    Box, 
    Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    updatePlaceMessage, 
    PlaceAttributes
} from "../../features/place/placesSlice"

import {
    PlaceFormLayout
} from "../../layouts/forms/PlaceFormLayout"

import {
    PlaceValidationSchema
} from "./PlaceValidationSchema"

import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    ChangeDateUserInfoNew 
} from '../../layouts/formatter/ChangeDateUserInfoNew'

import { 
    FormButtonBar
} from '../../layouts/menuBars/general/FormButtonsBar'


// Define interfaces

//Title and Initial values of the form 
interface PlaceUpdateFormProps {
    placeId: number,
    initialPlaceInfo: PlaceAttributes,
    updateUser?: string,
    updateTime?: Timestamp.AsObject,
}


/**
 * The Page component renders the admin page of the application.
 */
export const PlaceUpdateForm = (props: PlaceUpdateFormProps) => {  
    const dispatch = useAppDispatch()
    

    const form = useForm({
        initialValues: {
            room: props.initialPlaceInfo.room || '',
            rack: props.initialPlaceInfo.rack ||'',
            description: props.initialPlaceInfo.description ||'',
            organisationId: props.initialPlaceInfo.organisationId || 0,
        },

        validate: yupResolver(PlaceValidationSchema),

        transformValues: (values) => ({
            room: values.room,
            rack: values.rack,
            description: values.description,
            organisationId: Number(values.organisationId) || 0,
        }),
    })


    useEffect(() => {
        form.setFieldValue("room", props.initialPlaceInfo.room || '')
        form.setFieldValue("rack", props.initialPlaceInfo.rack || '')
        form.setFieldValue("description", props.initialPlaceInfo.description || '')
        form.setFieldValue("organisationId", props.initialPlaceInfo.organisationId || 0)
    }, [props, form]);


    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => {
                    const payload = {
                        id: props.placeId,
                        placeInfo: values,
                        updateUser: props.updateUser + '',
                    }                                    
                    dispatch(updatePlaceMessage(props.placeId,payload))
                })}                
            >
                <PlaceFormLayout
                    form={form}
                />
                <ChangeDateUserInfoNew
                     lastchangedate = {props.updateTime === undefined ? {seconds: 0, nanos: 0} : props.updateTime} 
                     lastchangeuser = {props.updateUser === undefined ? "" : props.updateUser}
                />      
                <Space 
                    h="xl" 
                />         
                <FormButtonBar/>
            </form>
        </Box>
    )
}