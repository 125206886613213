// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect 
} from 'react'

import { 
    Box,
    Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


import {
    ExternalStorageFormLayout
} from "../../layouts/forms/ExternalStorageFormLayout"

import {
    ExternalStorageValidationSchema
} from "./ExternalStorageValidationSchema"

import { 
    updateExternalStorageMessage,
    ExternalStorageAttributes
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'

import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    ChangeDateUserInfoNew 
} from '../../layouts/formatter/ChangeDateUserInfoNew'

// Define interfaces
interface ExternalStorageUpdateFormProps {
    externalStorageId: number
    initialExternalStorageInfo: ExternalStorageAttributes,
    updateUser?: string,
    updateTime?: Timestamp.AsObject,
}

/**
 * The ExternalStorageUpdateForm component renders the ExternalStorageUpdateForm page of the application.
 */

export const ExternalStorageUpdateForm = (props: ExternalStorageUpdateFormProps) => { 
    const dispatch = useAppDispatch()
    
    const form = useForm({
        initialValues: {
            subject: props.initialExternalStorageInfo.subject || '',
            requestText: props.initialExternalStorageInfo.requestText || '',
            startTimeId: props.initialExternalStorageInfo.startTimeId || 0,
            endTimeId: props.initialExternalStorageInfo.endTimeId || 0,
            organisationId: props.initialExternalStorageInfo.organisationId || 0,
            storageUnitRelationList: props.initialExternalStorageInfo.storageUnitRelationList || []
        },         


        validate: yupResolver(ExternalStorageValidationSchema),

        transformValues: (values) => ({
            subject: values.subject,
            requestText: values.requestText,
            startTimeId: values.startTimeId,
            endTimeId: values.endTimeId,
            organisationId: Number(values.organisationId) || 0,
            storageUnitRelationList: values.storageUnitRelationList
        }),
    })


    useEffect(() => {
        form.setFieldValue("subject", props.initialExternalStorageInfo.subject || '')
        form.setFieldValue("requestText", props.initialExternalStorageInfo.requestText || '')
        form.setFieldValue("startTimeId", props.initialExternalStorageInfo.startTimeId || 0)
        form.setFieldValue("endTimeId", props.initialExternalStorageInfo.endTimeId || 0)
        form.setFieldValue("organisationId", props.initialExternalStorageInfo.organisationId || 0)
        form.setFieldValue("storageUnitRelationList", props.initialExternalStorageInfo.storageUnitRelationList || [])
    }, [props, form]);


    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => dispatch(updateExternalStorageMessage(props.externalStorageId, values)))}
            >
                <ExternalStorageFormLayout
                    form={form}
                />
                <ChangeDateUserInfoNew
                     lastchangedate = {props.updateTime === undefined ? {seconds: 0, nanos: 0} : props.updateTime} 
                     lastchangeuser = {props.updateUser === undefined ? "" : props.updateUser}
                />    
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>
            </form>
        </Box>
    )
}