// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect 
} from 'react'

import { 
    Box, 
    Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    updateStorageUnitMessage, 
    StorageUnitAttributes,
} from "../../features/storageunit/storageUnitSlice"

import {
    StorageUnitFormLayout
} from "../../layouts/forms/StorageUnitFormLayout"

import {
    StorageUnitValidationSchema
} from "./StorageUnitValidationSchema"

import { 
    Timestamp
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    StorageUnitTypes 
} from "../../proto/sip/storeroom/storeroom_pb"

import { 
    ChangeDateUserInfoNew 
} from '../../layouts/formatter/ChangeDateUserInfoNew'

import { 
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'


// Define interfaces
interface StorageUnitUpdateFormProps {
    storageunitId: number,
    initialStorageUnitInfo: StorageUnitAttributes,
    updateUser?: string,
    updateTime?: Timestamp.AsObject,
}


/**
 * The Page component renders the admin page of the application.
 */
export const StorageUnitUpdateForm = (props: StorageUnitUpdateFormProps) => { 
    const dispatch = useAppDispatch() 

    const form = useForm({
        initialValues: {
            displayName: props.initialStorageUnitInfo.displayName || '',
            storageUnitType: props.initialStorageUnitInfo.storageUnitType || 0,
            description: props.initialStorageUnitInfo.description || '',
            isInStorageUnitId: props.initialStorageUnitInfo.isInStorageUnitId || 0,
            placeId: props.initialStorageUnitInfo.placeId || 0,
            organisationId: props.initialStorageUnitInfo.organisationId || 0,
        },

        validate: yupResolver(StorageUnitValidationSchema),

        transformValues: (values) => ({
            displayName: values.displayName,
            storageUnitType: values.storageUnitType as unknown as StorageUnitTypes,
            description: values.description,
            isInStorageUnitId: Number(values.isInStorageUnitId) || 0,
            placeId: Number(values.placeId) || 0,
            organisationId: Number(values.organisationId) || 0,
        }),
    })


    useEffect(() => {
        form.setFieldValue("displayName", props.initialStorageUnitInfo.displayName || '')
        form.setFieldValue("storageUnitType", props.initialStorageUnitInfo.storageUnitType || 0)
        form.setFieldValue("description", props.initialStorageUnitInfo.description || '')
        form.setFieldValue("isInStorageUnitId", props.initialStorageUnitInfo.isInStorageUnitId || 0)
        form.setFieldValue("placeId", props.initialStorageUnitInfo.placeId || 0)
        form.setFieldValue("organisationId", props.initialStorageUnitInfo.organisationId || 0)
     }, [props, form]);



    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => {
                    const payload = {
                        id: props.storageunitId,
                        storageUnitInfo: values,
                        updateUser: props.updateUser + '',
                    }            
                    dispatch(updateStorageUnitMessage(props.storageunitId,payload))
                })}
            >
                <StorageUnitFormLayout
                    form={form}
                />
                <ChangeDateUserInfoNew
                     lastchangedate = {props.updateTime === undefined ? {seconds: 0, nanos: 0} : props.updateTime} 
                     lastchangeuser = {props.updateUser === undefined ? "" : props.updateUser}
                /> 
                <Space 
                    h="xl" 
                />   
                <FormButtonBar/>
            </form>
        </Box>
    )
}