// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect 
} from 'react'

import { 
    Box, 
    Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    updateItemMessage,
    ItemAttributes
} from "../../features/item/itemsSlice"

import {
    ItemFormLayout
} from "../../layouts/forms/ItemFormLayout"

import {
    ItemValidationSchema
} from "./ItemValidationSchema"

import { 
    Timestamp 
} from "google-protobuf/google/protobuf/timestamp_pb"

import { 
    ChangeDateUserInfoNew 
} from '../../layouts/formatter/ChangeDateUserInfoNew'

import { 
    FormButtonBar
} from '../../layouts/menuBars/general/FormButtonsBar'



// Define interfaces
interface ItemUpdateFormProps {
    itemId: number,
    initialItemInfo: ItemAttributes,
    updateUser?: string,
    updateTime?: Timestamp.AsObject,
    deleted: boolean
}

/**
 * The Page component renders the Place Create Form page of the application.
 */
export const ItemUpdateForm = (props: ItemUpdateFormProps) => { 
    const dispatch = useAppDispatch() 

    const form = useForm({
        initialValues: {
            displayName: props.initialItemInfo.displayName || '',
            description: props.initialItemInfo.description ||'',
            tagsList: props.initialItemInfo.tagsList || [],
            organisationId: props.initialItemInfo.organisationId || 0,
            externalRent: props.initialItemInfo.externalRent || false,
            storageUnitRelationsList: props.initialItemInfo.storageUnitRelationsList || []
        },

        validate: yupResolver(ItemValidationSchema),

        transformValues: (values) => ({
            displayName: values.displayName,
            description: values.description,
            tagsList: values.tagsList,
            organisationId: Number(values.organisationId) || 0,
            externalRent: values.externalRent,
            storageUnitRelationsList: values.storageUnitRelationsList
        }),
    })


    useEffect(() => {
        form.setFieldValue("displayName", props.initialItemInfo.displayName || '')
        form.setFieldValue("description", props.initialItemInfo.description || '')
        form.setFieldValue("tagsList", props.initialItemInfo.tagsList || [])
        form.setFieldValue("organisationId", props.initialItemInfo.organisationId || 0)
        form.setFieldValue("externalRent", props.initialItemInfo.externalRent || false)
        form.setFieldValue("storageUnitRelationsList", props.initialItemInfo.storageUnitRelationsList || [])
    }, [props, form]);


    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => dispatch(updateItemMessage(props.itemId, values)))}
            >
                <ItemFormLayout
                    form={form}
                />
                <ChangeDateUserInfoNew
                     lastchangedate = {props.updateTime === undefined ? {seconds: 0, nanos: 0} : props.updateTime} 
                     lastchangeuser = {props.updateUser === undefined ? "" : props.updateUser}
                />    
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>
            </form>
        </Box>
    )
}