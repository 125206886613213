// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    selectExternalStorageDatesById 
} from "../../features/externalStorageDate/externalStorageDatesSlice"

import { 
    timeObjToMomentTime
} from "../../util/proto"


// Define interfaces


/**
 * The AdminPage component renders the admin page of the application.
 */
export const ExternalStorageDateFormatter = (externalStorageDateId: number) => {
    const externalStorageDates = useAppSelector(selectExternalStorageDatesById)
    
    const startTime = (externalStorageDates[externalStorageDateId] === undefined) ? {seconds: 0, nanos: 0} : externalStorageDates[externalStorageDateId].date

    return(
        timeObjToMomentTime(startTime).format("DD.MM.YYYY")
    )
}