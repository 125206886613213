// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Box,
    Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'


// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks


import {
    ExternalStorageFormLayout
} from "../../layouts/forms/ExternalStorageFormLayout"

import {
    ExternalStorageValidationSchema
} from "./ExternalStorageValidationSchema"

import { 
    createExternalStorageMessage
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'

import { 
    ExternalStorageStorageUnitRelationAttributes,
} from '../../proto/sip/storeroom/storeroom_pb'



// Define interfaces
interface ExternalStorageCreateFormProps {
    externalStorageId?: number
    organisationId?: number
}

/**
 * The ExternalStorageCreateForm component renders the ExternalStorageCreateForm page of the application.
 */

export const ExternalStorageCreateForm = (props: ExternalStorageCreateFormProps) => { 
    const dispatch = useAppDispatch()
    
    const form = useForm({
        initialValues: {
            subject: '',
            requestText: '',
            startTimeId: 0,
            endTimeId: 0,
            organisationId: props.organisationId || 0,
            storageUnitRelationList: [] as ExternalStorageStorageUnitRelationAttributes.AsObject[]
        },         


        validate: yupResolver(ExternalStorageValidationSchema),

        transformValues: (values) => ({
            subject: values.subject,
            requestText: values.requestText,
            startTimeId: values.startTimeId,
            endTimeId: values.endTimeId,
            organisationId: Number(values.organisationId) || 0,
            storageUnitRelationList: values.storageUnitRelationList
        }),
    })


    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => dispatch(createExternalStorageMessage(values)))}
            >
                <ExternalStorageFormLayout
                    form={form}
                />
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>
            </form>
        </Box>
    )
}