// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    useEffect, 
    useState 
} from "react"

import { 
    useTranslation
} from "react-i18next"

import sortBy from "lodash/sortBy"

import { 
    useNavigate
} from "react-router-dom"

import { 
    ActionIcon,
    Text,
    Group
} from "@mantine/core"

import { 
    DataTable,
    DataTableSortStatus 
} from "mantine-datatable"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch 
} from "../../hooks/useAppDispatch"

import { 
    GetExternalStorageResponse 
} from "../../features/externalStorage/externalStoragesSlice"

import {
    setExternalStorageSingleId
} from "../../features/externalStorage/externalStorageSingleIdSlice"

import { 
    PAGE_SIZES 
} from "../../app/Consts"

import { 
    OrganisationShortNameFormatter 
} from "../../layouts/formatter/OrganisationShortNameFormatter"

import { 
    useEditorStatus
} from "../../hooks/useEditorStatus"

import { 
    useIsOnAdminPage 
} from '../../hooks/useIsOnAdminPage'

import { 
    useActiveOrganisationId 
} from '../../hooks/useActiveOrganisationId'

import { 
    ExternalStorageDateFormatter 
} from "../../layouts/formatter/ExternalStorageDateFormatter"

import { 
    StorageUnitRelationsFormatter
} from "../../layouts/formatter/StorageUnitRelationsFormatter"

import {
    ConfirmedFormatter 
} from "../../layouts/formatter/ConfirmedFormatter"


// Define interfaces
interface ExternalStoragesTableProps {
    externalStoragesForTable: GetExternalStorageResponse[],
    isBeforeStart?: boolean
    isBeforeEnd?: boolean
    isArchive?: boolean
}


/**
 * The ExternalStorageRequestsTable component renders the ExternalStorageRequestsTable page of the application.
 */
export const ExternalStorageTable : React.FC<ExternalStoragesTableProps> = ({
    externalStoragesForTable, 
    isBeforeStart,
    isBeforeEnd, 
    isArchive,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [pageSize, setPageSize] = useState(PAGE_SIZES[1])
    const [page, setPage] = useState(1)
    const [records, setRecords] = useState(externalStoragesForTable.slice(0, pageSize))
    const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'id', direction: 'asc' })
   

    useEffect(() => {
         setPage(1)
    }, [pageSize])  
  
    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        const data = sortBy(externalStoragesForTable, sortableStatus.columnAccessor)
        setRecords(sortableStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to))
    }, [page, pageSize, sortableStatus, externalStoragesForTable])

    
    const activeOrganisationId = useActiveOrganisationId()
    const isEditor = useEditorStatus(activeOrganisationId)
    const isOnAdminPage = useIsOnAdminPage()

    
    const actionItems = (externalStorageId: number) => {
        if (isEditor || isOnAdminPage) {
            return (
                <Group spacing={2} position="right" noWrap>
                    <ActionIcon 
                        onClick={() => {
                            dispatch(setExternalStorageSingleId(externalStorageId))
                            navigate("view")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.VIEW} 
                            size = {16}                        
                            color="green" 
                        />                  
                    </ActionIcon>
                    <ActionIcon                      
                        onClick={() => {  
                            dispatch(setExternalStorageSingleId(externalStorageId))           
                            navigate("update")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.EDIT} 
                            size = {16}
                            color="blue"
                        />      
                    </ActionIcon>
                    <ActionIcon                     
                        onClick={() => {
                            dispatch(setExternalStorageSingleId(externalStorageId))
                            navigate("delete")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.DELETE} 
                            size = {16}
                            color="red" 
                        />   
                    </ActionIcon>
                </Group>
            )
        } else {
            return (
                <Group spacing={2} position="right" noWrap>
                    <ActionIcon 
                        onClick={() => {
                            dispatch(setExternalStorageSingleId(externalStorageId))
                            navigate("view")
                        }}
                    >
                        <Icon 
                            icon = {ICONS.VIEW} 
                            size = {16}                        
                            color="green" 
                        />    
                    </ActionIcon>    
                </Group>
            )
        }      
   }
    
        
    const columns = [
        {
            accessor: "id",
            title: t("ExternalStorages.id"),
            sortable: true,
            hidden: !isOnAdminPage
        },
        {
            accessor: "externalStorageInfo.subject",
            title: t("ExternalStorages.subject"),
            sortable: true,
        },
        {
            accessor: "externalStorageInfo.requestText",
            title: t("ExternalStorages.requestText"),
            sortable: true,
            hidden: !isOnAdminPage
        },
        {
            accessor: "externalStorageInfo.startTime",
            title: t("ExternalStorages.startTime"),
            sortable: true,
            render: (externalStorage: GetExternalStorageResponse) => ExternalStorageDateFormatter(externalStorage.externalStorageInfo!.startTimeId),
            hidden: !isBeforeStart 
        },
        {
            accessor: "externalStorageInfo.endTime",
            title: t("ExternalStorages.endTime"),
            sortable: true,
            formatter: (externalStorage: GetExternalStorageResponse) => ExternalStorageDateFormatter(externalStorage.externalStorageInfo!.endTimeId),
            hidden: !isBeforeEnd
        },
        {
            accessor: "externalStorageInfo.organisationId",
            title: t("Organisations.shortName"),
            sortable: true,
            render: (externalStorage: GetExternalStorageResponse) => OrganisationShortNameFormatter(externalStorage.externalStorageInfo!.organisationId),
            hidden: !isOnAdminPage,
        },
        {
            accessor: "externalStorageInfo.storageUnitRelationList",
            title: t("Storeroom.storageUnits"),
            sortable: true,
            render: (externalStorage: GetExternalStorageResponse) => StorageUnitRelationsFormatter(externalStorage.externalStorageInfo!.storageUnitRelationList), 
        },
        {
            accessor: "confirmed",
            title: t("ExternalStorages.confirmed"),
            sortable: true,
            render: (externalStorage: GetExternalStorageResponse) => ConfirmedFormatter(externalStorage.confirmationTime!),
            hidden: isArchive
        },
        {
            accessor: 'actions',
            title: <Text mr="xs">Row actions</Text>,
            render: (externalStorage: GetExternalStorageResponse) => actionItems(externalStorage.id),            
            hidden: isArchive       
        },    
    ]
    

    return(
        <DataTable
            striped
            highlightOnHover
            verticalSpacing="sm"
            fontSize="xs"
            records={records}
            columns={columns}
            totalRecords={externalStoragesForTable.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortableStatus}
            onSortStatusChange={setSortStatus}
        />  
    )
}