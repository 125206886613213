// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    Box, 
    Space 
} from '@mantine/core'

import { 
    useForm, 
    yupResolver 
} from '@mantine/form'

// Imports from vseth-canine-ui


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppDispatch
} from '../../hooks/useAppDispatch'

import { 
    createItemMessage 
} from "../../features/item/itemsSlice"

import {
    ItemFormLayout
} from "../../layouts/forms/ItemFormLayout"

import {
    ItemValidationSchema
} from "./ItemValidationSchema"

import { 
    ItemStorageUnitRelationAttributes 
} from '../../proto/sip/storeroom/storeroom_pb'

import {
    FormButtonBar 
} from '../../layouts/menuBars/general/FormButtonsBar'


// Define interfaces
interface ItemCreateFormProps {
    itemId?: number
    organisationId?: number
}

/**
 * The Page component renders the Place Create Form page of the application.
 */
export const ItemCreateForm = (props: ItemCreateFormProps) => { 
    const dispatch = useAppDispatch()

    const form = useForm({
        initialValues: {
            displayName: '',
            description: '',
            tagsList: [] as number[],
            organisationId: props.organisationId || 0,
            externalRent: false,
            storageUnitRelationsList: [] as ItemStorageUnitRelationAttributes.AsObject[]
        },

        validate: yupResolver(ItemValidationSchema),

        transformValues: (values) => ({
            displayName: values.displayName,
            description: values.description,
            tagsList: values.tagsList,
            organisationId: Number(values.organisationId) || 0,
            externalRent: values.externalRent,
            storageUnitRelationsList: values.storageUnitRelationsList
        }),
    })

    return (
        <Box 
            mx="auto"
        >
            <form 
                onSubmit={form.onSubmit((values) => dispatch(createItemMessage(values)))}
            >
                <ItemFormLayout
                    form={form}
                />
                <Space 
                    h="xl" 
                />
                <FormButtonBar/>
            </form>
        </Box>
    )
}