// Imports from external sources
// React, i18next, Router, Mantine, Further Stuff
import { 
    ActionIcon,
    Button,
    Grid, 
    Group, 
    NumberInput, 
    Select, 
    TextInput,
    Text
} from "@mantine/core"

import { 
    UseFormReturnType 
} from "@mantine/form"

import { 
    useTranslation 
} from "react-i18next"

import { 
    useLocation 
} from "react-router-dom"


// Imports from vseth-canine-ui
import {
    Icon,
    ICONS, 
} from "vseth-canine-ui"


// Imports from this projects
// Pages, Components, Features, Hooks
import { 
    useAppSelector 
} from "../../hooks/useAppSelector"

import { 
    ExternalStorageAttributes 
} from "../../features/externalStorage/externalStoragesSlice"

import { 
    SelectoptionExternalStorageDates 
} from "../../components/selectoptions/SelectoptionExternalStorageDates"

import { 
    selectOrganisationsById
} from "../../features/organisation/organisationsSlice"

import { 
    SelectoptionOrganisationStorageUnitsName 
} from "../../components/selectoptions/SelectoptionOrganisationStorageUnitsName"

import { 
    SelectoptionsAllOrganisationsNameNew 
} from "../../components/selectoptions/SelectoptionAllOrganisationsNameNew"

import { 
    DisabledTextInput 
} from "../formatter/DisabledTextInput"



/**
 * The Page component renders the admin page of the application.
 */
export const ExternalStorageFormLayout = ({ form }:{ form: UseFormReturnType<ExternalStorageAttributes>}) => {
    const { t } = useTranslation()
    const location = useLocation()
    
    const organisations = useAppSelector(selectOrganisationsById)
    
    const organisationIdValue = {...form.getInputProps('organisationId')}.value
    const selectoptionOrganisationStorageUnitsName = SelectoptionOrganisationStorageUnitsName(organisationIdValue,0)


    const organisationField = () => {
        if (location.pathname === "/admin/places/create") {
            return (
                <div>
                    <Select 
                        label = {t("Storeroom.organisation")}
                        placeholder = {t("Storeroom.organisation") + ''}
                        data={SelectoptionsAllOrganisationsNameNew()} 
                        searchable
                        {...form.getInputProps('organisationId')}      
                    />                             
                </div>                    
            )
        } else {            
            return (
                <DisabledTextInput
                    label = {t("Storeroom.organisation")} 
                    value = {(organisations[organisationIdValue] === undefined) ? "" : organisations[organisationIdValue].organisationInfo!.shortName}                
                />
            )
        }
    }

    const storageUnitRelationField = form.values.storageUnitRelationList.map((storageUnitRelation, index) => (
        <Group key={storageUnitRelation.storageUnitId} mt="xs">
            <Select 
                label = {t("Storeroom.storageUnit")}
                placeholder = {t("Storeroom.storageUnit") + ''}
                data={selectoptionOrganisationStorageUnitsName} 
                searchable
                {...form.getInputProps(`storageUnitRelationsList.${index}.storageUnitId`)} 
            />  
            <NumberInput 
                label = {t("ExternalStorages.quantity")}
                placeholder = {t("ExternalStorages.quantity") + ''}   
                {...form.getInputProps(`storageUnitRelationsList.${index}.quantity`)}
            />
            <ActionIcon color="red" onClick={() => form.removeListItem('storageUnitRelationList', index)}>
                <Icon 
                    icon = {ICONS.DELETE} 
                    size = {16}
                    color="red" 
                />   
            </ActionIcon>
        </Group>
      ))


    return(
        <Grid>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <TextInput
                    label = {t("ExternalStorages.subject")}
                    placeholder = {t("ExternalStorages.subject") + ''}
                    {...form.getInputProps('subject')}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <TextInput
                    label = {t("ExternalStorages.requestText")}
                    placeholder = {t("ExternalStorages.requestText") + ''}
                    {...form.getInputProps('requestText')}
                />
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <Select 
                    label = {t("ExternalStorages.startTimeId")}
                    placeholder = {t("ExternalStorages.startTimeId") + ''}
                    searchable
                    data={SelectoptionExternalStorageDates()} 
                    {...form.getInputProps('startTimeId')}      
                />        
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                <Select 
                    label = {t("ExternalStorages.endTimeId")}
                    placeholder = {t("ExternalStorages.endTimeId") + ''}
                    searchable
                    data={SelectoptionExternalStorageDates()} 
                    {...form.getInputProps('endTimeId')}      
                />        
            </Grid.Col>
            <Grid.Col 
                md={12} 
                lg={6}
            >
                {organisationField()}
            </Grid.Col>   
            <Grid.Col 
                md={12} 
                lg={12}
            >
                {storageUnitRelationField.length > 0 ? (
                    <Group mb="xs">
                        <Text weight={500} size="sm" pr={90}>
                            {t("Storeroom.storageUnit")}
                        </Text>
                        <Text weight={500} size="sm" pr={90}>
                            {t("ExternalStorages.quantity")}
                        </Text>
                        </Group>
                ) : (
                    <Text color="dimmed" align="center">
                    ---
                    </Text>
                )}

                {storageUnitRelationField}

                <Group position="center" mt="md">
                    <Button
                        onClick={() =>
                            form.insertListItem('storageUnitRelationList', { storageUnitId: 0, quantity: 0, key: 0 })
                        }
                    >
                        {t("ExternalStorages.addNewOne")}
                    </Button>
                </Group>
            </Grid.Col>              
        </Grid>
    )
} 